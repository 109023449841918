import { Component, OnInit } from '@angular/core';
import { DashboardGridModel, userDetails } from '@app/_models';
import { CandidateService } from '@app/_services';
import { AccountService, CompanyService } from '@app/_services';
import { Assessment, UserAssessment, RoleEnum, UserAssessmentStatus, UserAssessmentGridModel } from '@app/_models';
declare var $: any;
declare const manageLoader: any;
import { Router } from '@angular/router';
import { NotificationService } from '../_components/notification/notification.service';
import { NotificationPosition } from '../_components/notification/notification.models';
import { Observable, interval } from 'rxjs';
import { MaterialNotificationComponent } from '../_components/material-notification.component';
import {FormControl, FormGroup} from '@angular/forms';


@Component({ templateUrl: 'home.component.html' },
)
export class HomeComponent implements OnInit {
  roleEnum;
  isCandidate: boolean = false;
  isAdmin: boolean = false;
  assessmentData: Assessment[] = [];
  userAssessmentData: UserAssessmentGridModel[] = [];
  dashboardData: DashboardGridModel;
  userAssessmentStatus;
  user: userDetails;
  isLoading = false;
  minDate = new FormControl(new Date(new Date().setDate(new Date().getDate() - 7)));
  maxDate = new FormControl(new Date());


  constructor(private accountService: AccountService,
    private candidateService: CandidateService,
    private notification: NotificationService,
    private router: Router,
    private companyService: CompanyService) {
    this.roleEnum = RoleEnum;
    this.userAssessmentStatus = UserAssessmentStatus;
    this.accountService.getuserdata().subscribe((response: any) => {
      var user = response.data;
      if (user.RoleId == this.roleEnum.Candidate) {
        this.isCandidate = true;
        this.getUserAssessmentList();
      } else if (user.RoleId == this.roleEnum.PortalAdmin) {
        this.isAdmin = true;
        this.getDashboardDetails();
      }     
    });
  }

  startUserAssessment(assessment: UserAssessment) {
    if (assessment.UserAssessmentStatusId.toString() === this.userAssessmentStatus.Pause) {
      this.candidateService.restartAssessment(assessment.UserAssessmentId).subscribe((response: any) => {
        this.router.navigate(['/assess/assterms', assessment.UserAssessmentId]);
      });
    }
    else {
      this.router.navigate(['/assess/assterms', assessment.UserAssessmentId]);
    }
  }

  getColorForStatus(UserAssessmentStatusId: number): string {
    let returnColor = '';
    if (UserAssessmentStatusId.toString() === this.userAssessmentStatus.Start) {
      returnColor = 'btn-palegreen';
    }
    else if (UserAssessmentStatusId.toString() === this.userAssessmentStatus.Pause) {
      returnColor = 'btn-yellow';
    }
    else {
      returnColor = 'btn-darkorange';
    }
    return returnColor;
  }

  replaceStatusByName(UserAssessmentStatusId: number): string {
    let returnStstus = '';
    if (UserAssessmentStatusId.toString() === this.userAssessmentStatus.Start) {
      returnStstus = 'Start';
    }
    else if (UserAssessmentStatusId.toString() === this.userAssessmentStatus.Pause) {
      returnStstus = 'Pause';
    }
    else if (UserAssessmentStatusId.toString() === this.userAssessmentStatus.Restart) {
      returnStstus = 'Restart';
    }
    else if (UserAssessmentStatusId.toString() === this.userAssessmentStatus.Complete) {
      returnStstus = 'Complete';
    }
    else if (UserAssessmentStatusId.toString() === this.userAssessmentStatus.Abort) {
      returnStstus = 'Abort';
    }
    else if (UserAssessmentStatusId.toString() === this.userAssessmentStatus.TimeOut) {
      returnStstus = 'TimeOut';
    }
    else if (UserAssessmentStatusId.toString() === this.userAssessmentStatus.Started) {
      returnStstus = 'Started';
    }
    else {
      returnStstus = 'NA';
    }
    return returnStstus;
  }

  getUserAssessmentList() {
    this.userAssessmentData = [];
    manageLoader('load');
    this.isLoading = true;
    this.candidateService.getUserAssessmentGridList().subscribe((response: any) => {
      this.userAssessmentData.push(...response.data);
      manageLoader();
      this.isLoading = false;
    });
  }

  getTotalScoreNumber(assessment: UserAssessmentGridModel): string {
    let returnVal = '';
    let score = assessment.UserAssessmentStatusId.toString() === this.userAssessmentStatus.Complete ? assessment.TotalScore : assessment.RunningScore;
    if (score) {
      returnVal = '/' + assessment.TotalQuestions;
    }
    return returnVal;
  }

  ngOnInit() {
  }

  onClick(time) {
    this.showNotification('Top right Notification!', time, NotificationPosition.BOTTOM_RIGHT);
  }

  showNotification(
    title: string,
    time: number,
    position: NotificationPosition
  ): Observable<any> {
    return this.notification.show({
      component: MaterialNotificationComponent,
      position: position,
      duration: +time * 1000,
      data: {
        title
      }
    }).onDispose;
  }

  getDashboardDetails() {
    manageLoader('load');
    this.isLoading = true;
    let startDate: Date = new Date(this.minDate.value);
    let endDate: Date = new Date(this.maxDate.value);
    this.companyService.getDashboardDetails(this.getFormattedDate(startDate), this.getFormattedDate(endDate)).subscribe((response: any) => {
      this.dashboardData = response.data;
      console.log(this.dashboardData);
      manageLoader();
      this.isLoading = false;
    });
  }

  getFormattedDate(date: Date) {
    return `${new Date(date).getMonth() + 1}-${new Date(date).getDate()}-${new Date(date).getFullYear()}`;
  }
}