<!-- header-area -->
<header id="sticky-header" class="transparent-header header-style-two">
    <div class="container custom-container">
        <div class="menu-area">
            <div class="row">
                <div class="col-12">
                    <div class="mobile-nav-toggler"><i class="fas fa-bars"></i></div>
                    <div class="menu-wrap">
                        <nav class="menu-nav">
                            <div class="logo">
                                <a routerLink="/home"><img src="../../assets/img/skillTestLogo-transparent.png"
                                        alt="Logo"></a>
                            </div>
                            <div class="navbar-wrap main-menu d-none d-lg-flex">
                                <ul class="navigation" style="width: 95%;">
                                    <li class="menu__button menu__button--arrow"
                                        *ngIf="((user?.RoleId) == roleEnum.Recruiter) || ((user?.RoleId) == roleEnum.ProjectManager) ">
                                        Assessments
                                        <div class="dropdown-container">
                                            <ul>
                                                <li *ngIf="((user?.RoleId) == roleEnum.Recruiter) || ((user?.RoleId) == roleEnum.ProjectManager) "
                                                    class="sub-menu-li">
                                                    <a href="#" routerLink="/assess/assessment"
                                                        [routerLinkActive]="['activeroute']" class="sub-menu-a">
                                                        <i class="icon-check"></i> Assessment
                                                    </a>
                                                </li>
                                                <li *ngIf="((user?.RoleId) == roleEnum.Recruiter) || ((user?.RoleId) == roleEnum.ProjectManager) "
                                                    class="sub-menu-li">
                                                    <a href="#" routerLink="/assess/assesques"
                                                        [routerLinkActive]="['activeroute']" class="sub-menu-a">
                                                        <i class="icon-check"></i> Frame my own questions
                                                    </a>
                                                </li>
                                            </ul>
                                        </div>
                                    </li>
                                    <li class="menu__button menu__button--arrow"
                                        *ngIf="((user?.RoleId) == roleEnum.Admin) || ((user?.RoleId) == roleEnum.PortalAdmin) || ((user?.RoleId) == roleEnum.SeniorManagement) || ((user?.RoleId) == roleEnum.QuestionOwner)">
                                        Add
                                        <div class="dropdown-container">
                                            <ul>
                                                <li *ngIf="((user?.RoleId) == roleEnum.Admin) || ((user?.RoleId) == roleEnum.PortalAdmin) || ((user?.RoleId) == roleEnum.SeniorManagement) || ((user?.RoleId) == roleEnum.Approver)"
                                                    class="sub-menu-li">
                                                    <a href="#" routerLink="/users/subuser"
                                                        [routerLinkActive]="['activeroute']" class="sub-menu-a">
                                                        <i class="icon-check"></i> Add Sub User
                                                    </a>
                                                </li>
                                                <li *ngIf="((user?.RoleId) == roleEnum.PortalAdmin) "
                                                    class="sub-menu-li">
                                                    <a href="#" routerLink="/users/regclient"
                                                        [routerLinkActive]="['activeroute']" class="sub-menu-a">
                                                        <i class="icon-check"></i> Register Client
                                                    </a>
                                                </li>
                                                <li *ngIf="((user?.RoleId) == roleEnum.PortalAdmin) || ((user?.RoleId) == roleEnum.QuestionOwner)"
                                                    class="sub-menu-li">
                                                    <a href="#" routerLink="/assess/primarydomain"
                                                        [routerLinkActive]="['activeroute']" class="sub-menu-a">
                                                        <i class="icon-check"></i> Domain
                                                    </a>
                                                </li>
                                                <li *ngIf="((user?.RoleId) == roleEnum.PortalAdmin) || ((user?.RoleId) == roleEnum.QuestionOwner)"
                                                    class="sub-menu-li">
                                                    <a href="#" routerLink="/assess/assessdomain"
                                                        [routerLinkActive]="['activeroute']" class="sub-menu-a">
                                                        <i class="icon-check"></i> Sub Domain
                                                    </a>
                                                </li>
                                                <li *ngIf="((user?.RoleId) == roleEnum.PortalAdmin) || ((user?.RoleId) == roleEnum.QuestionOwner) || ((user?.RoleId) == roleEnum.Approver) "
                                                    class="sub-menu-li">
                                                    <a href="#" routerLink="/ques/questions"
                                                        [routerLinkActive]="['activeroute']" class="sub-menu-a">
                                                        <i class="icon-check"></i> Questions
                                                    </a>
                                                </li>
                                                <li *ngIf="((user?.RoleId) == roleEnum.PortalAdmin) || ((user?.RoleId) == roleEnum.QuestionOwner) || ((user?.RoleId) == roleEnum.Approver) "
                                                    class="sub-menu-li">
                                                    <a href="#" routerLink="/ques/skillrpt"
                                                        [routerLinkActive]="['activeroute']" class="sub-menu-a">
                                                        <i class="icon-check"></i> Skill Report
                                                    </a>
                                                </li>
                                                <li *ngIf="((user?.RoleId) == roleEnum.PortalAdmin)"
                                                    class="sub-menu-li">
                                                    <a href="#" routerLink="/assess/clientassrpt"
                                                        [routerLinkActive]="['activeroute']" class="sub-menu-a">
                                                        <i class="icon-check"></i> Client Assessment Report
                                                    </a>
                                                </li>
                                            </ul>
                                        </div>
                                    </li>
                                    <li class="menu__button menu__button--arrow">{{user?.UserName}}
                                        <div class="dropdown-container">
                                            <ul>
                                                <li class="sub-menu-li">
                                                    <a routerLink="/users/changepwd" class="sub-menu-a">
                                                        <i class="icon-settings"></i> Change Password?
                                                    </a>
                                                </li>
                                                <li class="sub-menu-li">
                                                    <a (click)="logout()" class="sub-menu-a">
                                                        <i class="icon-logout"></i> Log Out
                                                    </a>
                                                </li>
                                            </ul>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                            <div class="header-action">
                                <!-- <ul class="list-wrap">
                                    <li class="offcanvas-menu">
                                        <a class="menu-tigger">
                                            <span></span>
                                            <span></span>
                                            <span></span>
                                        </a>
                                    </li>
                                </ul> -->
                            </div>
                        </nav>
                    </div>
                    <!-- Mobile Menu  -->
                    <div class="mobile-menu">
                        <nav class="menu-box">
                            <div class="close-btn"><i class="fas fa-times"></i></div>
                            <div class="nav-logo">
                                <a href="index.html"><img src="../../assets/img/skillTestLogo-transparent.png"
                                        alt="Logo"></a>
                            </div>

                            <div class="menu-outer">
                                <!--Here Menu Will Come Automatically Via Javascript / Same Menu as in Header-->
                            </div>
                            <div class="social-links">
                                <ul class="clearfix list-wrap">
                                    <li><a href="#"><i class="fab fa-facebook-f"></i></a></li>
                                    <li><a href="#"><i class="fab fa-twitter"></i></a></li>
                                    <li><a href="#"><i class="fab fa-instagram"></i></a></li>
                                    <li><a href="#"><i class="fab fa-linkedin-in"></i></a></li>
                                    <li><a href="#"><i class="fab fa-youtube"></i></a></li>
                                </ul>
                            </div>
                        </nav>
                    </div>
                    <div class="menu-backdrop"></div>
                    <!-- End Mobile Menu -->
                </div>
            </div>
        </div>
    </div>
    <!-- offCanvas-menu -->
    <div class="extra-info">
        <div class="close-icon menu-close">
            <button><i class="far fa-window-close"></i></button>
        </div>
        <div class="logo-side mb-30">
            <a href="index.html"><img src="../../assets/img/skillTestLogo-transparent.png" alt="Logo"></a>
        </div>
        <div class="side-info mb-30">
            <div class="contact-list mb-30">
                <h4>Office Address</h4>
                <p>1st Block, 226 2nd Floor, <br />100 Feet Rd, HRBR Layout, <br />Kalyan Nagar, Bengaluru,<br />
                    Karnataka 560043</p>
            </div>
            <div class="contact-list mb-30">
                <h4>Phone Number</h4>
                <p>+91 98805 65578</p>

            </div>
            <div class="contact-list mb-30">
                <h4>Email Address</h4>
                <p>info@myskilltest.com</p>
                <p>support@myskilltest.com</p>
            </div>
        </div>
        <div class="social-icon-right mt-30">
            <a href="#"><i class="fab fa-facebook-f"></i></a>
            <a href="#"><i class="fab fa-twitter"></i></a>
            <a href="#"><i class="fab fa-google-plus-g"></i></a>
            <a href="#"><i class="fab fa-instagram"></i></a>
        </div>
    </div>
    <div class="offcanvas-overly"></div>
    <!-- offCanvas-menu-end -->
</header>
<!-- header-area-end -->